export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state) => {
  //Calculate items price
  state.itemsPrice = addDecimals(
    state.cartItems.reduce(
      (acc, item) => acc + item.selectedPrice * item.qty,
      0
    )
  );

  //Calculate shipping price (order over $95 free, else $15)
  state.shippingPrice = addDecimals(state.itemsPrice > 94 ? 0 : 15);

  //Calculate tax price (7.2% tax)
  state.taxPrice = addDecimals(Number(0.072 * state.itemsPrice).toFixed(2));

  //Calculate total price
  state.totalPrice = (
    Number(state.itemsPrice) +
    Number(state.shippingPrice) +
    Number(state.taxPrice)
  ).toFixed(2);

  localStorage.setItem('cart', JSON.stringify(state));

  return state;
};
