import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FaEdit, FaTrash, FaCheck, FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Paginate from '../../components/Paginate';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import {
  useGetArtworksQuery,
  useCreateArtworkMutation,
  useDeleteArtworkMutation,
} from '../../slices/artworksApiSlice';
import { formatPriceIntoString } from '../../utils/priceHelper';

const ArtworkListScreen = () => {
  const { pageNumber } = useParams();
  const { data, isLoading, error, refetch } = useGetArtworksQuery({
    pageNumber,
  });
  const [createArtwork, { isLoading: loadingCreate }] =
    useCreateArtworkMutation();
  const [deleteArtwork, { isLoading: loadingDelete }] =
    useDeleteArtworkMutation();

  const deleteHandler = async (id) => {
    if (window.confirm('Are you sure you want to delete this artwork?')) {
      try {
        await deleteArtwork(id);
        refetch();
        toast.success('Artwork successfully deleted!');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  const createArtworkHandler = async () => {
    if (window.confirm('Are you sure you want to create a new product?')) {
      try {
        await createArtwork();
        refetch();
        toast.success('Sample artwork was successfully created!');
      } catch (error) {
        toast.error(error?.data?.message || error.error);
      }
    }
  };

  return (
    <>
      <Row className="align-tems-center">
        <Col>
          <h1>Artworks</h1>
        </Col>
        <Col className="text-end">
          <Button className="btn-sm m-3" onClick={createArtworkHandler}>
            <FaEdit />
            Create New Artwork
          </Button>
        </Col>
      </Row>
      {loadingCreate && <Loader />}
      {loadingDelete && <Loader />}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Table striped hover responsive className="table-sm">
            <thead>
              <tr>
                <td>ID</td>
                <td>NAME</td>
                <td>PRICE</td>
                {/* [{_id: 123, ss: 50},{_id: 123, ms: 125} ] */}
                <td>SIZE</td>
                <td>CATEGORY</td>
                <td>RELEASE DATE</td>
                <td>EDITION NUMBER</td>
                <td>DESCRIPTION</td>
                <td>FEATURED</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {data.artworks.map((artwork) => {
                const formattedPrice = formatPriceIntoString(artwork.price);
                return (
                  <tr key={artwork._id}>
                    <td>{artwork._id}</td>
                    <td>{artwork.name}</td>
                    <td>{formattedPrice}</td>
                    <td>{artwork.size.join(', ')}</td>
                    <td>{artwork.category}</td>
                    <td>{artwork.releaseDate}</td>
                    <td>{artwork.editionNumber}</td>
                    <td>{artwork.description}</td>
                    <td>
                      {artwork.featured ? (
                        <FaCheck style={{ color: 'green' }}></FaCheck>
                      ) : (
                        <FaTimes style={{ color: 'red' }}></FaTimes>
                      )}
                    </td>
                    <td>
                      <LinkContainer to={`/admin/artwork/${artwork._id}/edit`}>
                        <Button variant="light" className="btn-sm mx-2">
                          <FaEdit />
                        </Button>
                      </LinkContainer>
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => deleteHandler(artwork._id)}
                      >
                        <FaTrash style={{ color: 'white' }} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <Paginate
            pages={data.pages}
            page={data.page}
            isAdmin={true}
          ></Paginate>
        </>
      )}
    </>
  );
};

export default ArtworkListScreen;
