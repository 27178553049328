import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Rating from './Rating';

const Artwork = ({ artwork }) => {
  return (
    <Card className="my-3 p-3 rounded">
      <Link to={`/artwork/${artwork._id}`}>
        <Card.Img src={artwork.image} variant="top" />
      </Link>
      <Card.Body>
        <Link to={`/artwork/${artwork._id}`}>
          <Card.Title as="div" className="artwork-title">
            <strong>{artwork.name}</strong>
          </Card.Title>
        </Link>
        <Card.Text as="div">
          <Rating
            value={artwork.rating}
            text={`${artwork.numReviews} reviews`}
          />
        </Card.Text>
        <Card.Text as="h3">${Object.values(artwork.price[0])[1]}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Artwork;
