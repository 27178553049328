export const sanitizePrice = (priceItem) => {
  const sanitizedPrice = {};
  for (const key in priceItem) {
    if ([key] !== '_id') {
      sanitizedPrice[key] = priceItem[key];
    }
  }
  return sanitizedPrice;
};

export function formatPriceIntoString(data) {
  const result = data.map((obj) => {
    const keyValuePairs = [];
    for (const key in obj) {
      if (key !== '_id') {
        keyValuePairs.push(`${key}:${obj[key]}`);
      }
    }
    return keyValuePairs.join(',');
  });

  return result.join(',');
}

export function formatStringIntoPriceArray(formattedString) {
  const keyValuePairs = formattedString.split(',');
  const data = [];

  for (const pair of keyValuePairs) {
    const items = pair.split(':');
    if (items.length === 2) {
      const key = items[0].trim();
      const value = parseInt(items[1].trim(), 10); // Assuming values are integers
      const obj = { [key]: value };
      data.push(obj);
    }
  }

  return data;
}
