import { Row, Col, Image } from 'react-bootstrap';
import React from 'react';
import { bio } from '../assets/text/bio';
import { atTheStudio } from '../assets/images';

const AboutScreen = () => {
  const formattedBio = bio.bio.split('\n').map((paragraph, index) => (
    <React.Fragment key={index}>
      {paragraph}
      <br />
    </React.Fragment>
  ));
  return (
    <Row>
      <Col className="col-md-4 mx-auto pt-4" md={6}>
        {formattedBio}
      </Col>
      <Col className="col-md-4 mx-auto pt-4" md={3}>
        <Image src={atTheStudio} alt="at the studio" fluid rounded></Image>
        <p className="text-center pt-4">At the studio with my apprentices</p>
      </Col>
    </Row>
  );
};

export default AboutScreen;
