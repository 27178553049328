import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import FormContainer from '../../components/FormContainer';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import {
  useUpdateArtworkMutation,
  useGetArtworkDetailsQuery,
  useUploadArtworkImageMutation,
} from '../../slices/artworksApiSlice';
// import { formatPriceIntoString } from '../../utils/priceHelper';

// TODO: fix price update when possible
const ArtworkEditScreen = () => {
  const { id: artworkId } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState('');
  // const [price, setPrice] = useState('');
  const [size, setSize] = useState('');
  const [image, setImage] = useState('');
  const [releaseDate, setReleaseDate] = useState('');
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState(0);
  const [editionNumber, setEditionNumber] = useState(0);
  const [description, setDescription] = useState('');
  const [featured, setFeatured] = useState(false);

  const {
    data: artwork,
    isLoading,
    error,
    refetch,
  } = useGetArtworkDetailsQuery(artworkId);

  const [updateArtwork, { isLoading: loadingUpdate }] =
    useUpdateArtworkMutation();

  const [uploadArtworkImage, { isLoading: loadingUpload }] =
    useUploadArtworkImageMutation();

  useEffect(() => {
    if (artwork) {
      setName(artwork.name);
      // setPrice(formatPriceIntoString(artwork.price));
      setSize(artwork.size.join(', '));
      setImage(artwork.image);
      setReleaseDate(artwork.releaseDate);
      setCategory(artwork.category);
      setCountInStock(artwork.countInStock);
      setDescription(artwork.description);
      setEditionNumber(artwork.editionNumber);
      setFeatured(artwork.featured);
    }
  }, [artwork]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      await updateArtwork({
        artworkId,
        name,
        // price,
        size,
        image,
        releaseDate,
        category,
        description,
        countInStock,
        editionNumber,
        featured,
      });
      toast.success('Artwork updated');
      refetch();
      navigate('/admin/artworklist');
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const uploadFileHandler = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    try {
      const res = await uploadArtworkImage(formData).unwrap();
      toast.success(res.message);
      setImage(res.image);
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  return (
    <>
      <Link to="/admin/artworklist" className="btn btn-light my-3">
        Go Back
      </Link>
      <FormContainer>
        <h1>Edit Artwork</h1>
        {loadingUpdate && <Loader />}
        {isLoading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name" className="my-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                palceholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            {/* <Form.Group controlId="price" className="my-2">
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                palceholder="Enter price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              ></Form.Control>
            </Form.Group> */}

            <Form.Group controlId="size" className="my-2">
              <Form.Label>Size</Form.Label>
              <Form.Control
                type="text"
                palceholder="Separate by commas"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="image" className="my-2">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter image url"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                label="choose file"
                onChange={uploadFileHandler}
              ></Form.Control>
            </Form.Group>
            {loadingUpload && <Loader />}

            <Form.Group controlId="releaseDate" className="my-2">
              <Form.Label>Release Date</Form.Label>
              <Form.Control
                type="text"
                palceholder="Enter release date"
                value={releaseDate}
                onChange={(e) => setReleaseDate(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="category" className="my-2">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                palceholder="Enter category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="countInStock" className="my-2">
              <Form.Label>Count In Stock</Form.Label>
              <Form.Control
                type="number"
                palceholder="Enter count"
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
                className="my-2"
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="editionNumber" className="my-2">
              <Form.Label>Edition Number</Form.Label>
              <Form.Control
                type="number"
                palceholder="Enter edition"
                value={editionNumber}
                onChange={(e) => setEditionNumber(e.target.value)}
                className="my-2"
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="description" className="my-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                palceholder="Enter description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="featured" className="my-2">
              <Form.Check
                type="checkbox"
                label="Featured?"
                checked={featured}
                onChange={(e) => setFeatured(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Button type="submit" variant="primary" className="my-2">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default ArtworkEditScreen;
