import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Form,
  Button,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Rating from '../components/Rating';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Meta from '../components/Meta';
import {
  useGetArtworkDetailsQuery,
  useCreateReviewMutation,
} from '../slices/artworksApiSlice';
import { addToCart } from '../slices/cartSlice';
import { sizeMap } from '../utils/sizeHelpers';

const ArtworkScreen = () => {
  const { id: artworkId } = useParams();
  const {
    data: artwork,
    isLoading,
    refetch,
    error,
  } = useGetArtworkDetailsQuery(artworkId);

  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [price, setPrice] = useState(0);
  const [size, setSize] = useState('');

  useEffect(() => {
    if (!isLoading) {
      setSize(artwork.size[0]);
      setPrice(Object.values(artwork.price[0])[1]);
    }
  }, [artwork, isLoading]);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [createReview, { isLoading: loadingArtworkReview }] =
    useCreateReviewMutation();
  const { userInfo } = useSelector((state) => state.auth);

  const addToCartHandler = () => {
    dispatch(
      addToCart({ ...artwork, qty, selectedPrice: price, selectedSize: size })
    );
    navigate('/cart');
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await createReview({
        artworkId,
        rating,
        comment,
      }).unwrap();
      refetch();
      toast.success('Review Submitted');
      setRating(0);
      setComment('');
    } catch (error) {
      toast.error(error?.data?.message || error.error);
    }
  };

  if (isLoading) return;

  const dynamicPriceMap = artwork.price.reduce(
    (result, obj) => {
      for (const key in obj) {
        if (key !== '_id') {
          result[key] = obj[key];
        }
      }
      return result;
    },
    { sv: 50 }
  );

  return (
    <>
      <Link className="btn btn-light my-3" to="/">
        Go Back
      </Link>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Meta title={artwork.name} />
          <Row>
            <Col md={5}>
              <Image src={artwork.image} alt={artwork.name} fluid className="border border-secondary-subtle"/>
            </Col>
            <Col md={4}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>{artwork.name}</h3>
                </ListGroup.Item>
                {/* TODO:  uncomment if reviews are introduced again */}
                {/* <ListGroup.Item>
                  <Rating
                    value={artwork.rating}
                    text={`${artwork.numReviews} reviews`}
                  />
                </ListGroup.Item> */}
                <ListGroup.Item>Price: ${dynamicPriceMap[size]}</ListGroup.Item>
                <ListGroup.Item>
                  Edition of: {artwork.editionNumber}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Description:</strong> {artwork.description}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Release date:</strong> {artwork.releaseDate}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card>
                <ListGroup variant="flush">
                  {/* Size start */}
                  <ListGroup.Item>
                    <Row>
                      <Col>Size:</Col>
                      <Col>
                        {artwork.size.length === 1 ? (
                          <strong>{sizeMap[size]}</strong>
                        ) : (
                          <Form.Control
                            as="select"
                            value={size}
                            onChange={(e) => {
                              setSize(e.target.value);
                              setPrice(dynamicPriceMap[e.target.value]);
                            }}
                          >
                            {artwork.size.map((size) => {
                              return (
                                <option key={size} value={size}>
                                  {sizeMap[size]}
                                </option>
                              );
                            })}
                          </Form.Control>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {/* Size end */}
                  {/* Price start */}
                  <ListGroup.Item>
                    <Row>
                      <Col>Price:</Col>
                      <Col>
                        <strong>${dynamicPriceMap[size]}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {/* Price end */}
                  <ListGroup.Item>
                    <Row>
                      <Col>Status:</Col>
                      <Col>
                        <strong>
                          {artwork.countInStock > 0 ? (
                            artwork.countInStock < 5 ? (
                              <div style={{ color: 'red' }}>
                                Only {artwork.countInStock} left
                              </div>
                            ) : (
                              'Available'
                            )
                          ) : (
                            'Sold Out'
                          )}
                        </strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  {artwork.countInStock > 0 && (
                    <ListGroup.Item>
                      <Row>
                        <Col>Qty:</Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(Number(e.target.value))}
                          >
                            {[...Array(artwork.countInStock).keys()].map(
                              (key) => {
                                return (
                                  <option key={key + 1} value={key + 1}>
                                    {key + 1}
                                  </option>
                                );
                              }
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                  <ListGroup.Item>
                    <Button
                      className="btn-block"
                      type="button"
                      disabled={artwork.countInStock === 0}
                      onClick={addToCartHandler}
                    >
                      Add To Cart
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
           {/* TODO:  uncomment if reviews are introduced again */}
          {/* <Row className="review">
            <Col md={6}>
              <h2>Reviews</h2>
              {artwork.reviews.length === 0 && <Message>No Reviews</Message>}
              <ListGroup variant="flush">
                {artwork.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>
                      <Rating value={review.rating}></Rating>
                      <p>{review.createdAt.substring(0, 10)}</p>
                      <p>{review.comment}</p>
                    </strong>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item>
                  <h2>Write a Collector Review</h2>
                  {loadingArtworkReview && <Loader />}
                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group contorlId="rating" className="my-w">
                        <Form.Label>Rating</Form.Label>
                        {/* Adjust rating here */}
                        {/* <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="...">Select</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group contorlId="comment" className="my-2">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        disabled={loadingArtworkReview}
                        type="submit"
                        variant="primary"
                      >
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please <Link to="/login">sign in</Link> to write a review
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row> */}
        </>
      )}
    </>
  );
};

export default ArtworkScreen;
