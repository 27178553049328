export const sizeMap = {
  ss: `13"x13"`,
  sv: `13"x19"`,
  sh: `19"x13"`,
  ms: `22"x22"`,
  mv: `22"x28"`,
  mh: `28"x22"`,
  postcard1: `4"x6"`,
  postcard2: `4.5"x6.5"`,

};
