export const bio = {
  bio: `Originally from Ukraine, Limbism is a visionary artist who has made a significant mark on the contemporary art scene with the emergence of the limbism - an innovative art movement that redefines the human form in a uniquely expressive manner.
  
    Limbism embarked on their artistic journey in 2012, initially creating intricate ink-on-paper realistic paintings of the human figure. These early works showcased a remarkable skill in capturing the nuances of the human form with breathtaking precision.
    
    Later, Limbism's creative spirit led them to explore new stylistic directions. They found inspiration in the art of deconstruction and reconstruction, ultimately giving birth to limbism art style as we know it today. In this avant-garde movement, Limbism disassembles the human body into its constituent parts—limbs, torsos, and fragments—and masterfully reconstructs them into captivating and recognizable forms and figures.
    
    Limbism's inception with flower compositions was just the beginning of a remarkable artistic evolution. Their artistic journey has expanded to encompass elements of pop art, infusing their creations with vibrant colors, bold lines, and a playful yet profound exploration of human anatomy and its place in contemporary culture.
    
    Through their unique art style, Limbism invites viewers to engage with art in a fresh and thought-provoking way. Each piece tells a story, blending realism and abstraction, exploring the beauty of the human body and the boundless possibilities of artistic expression.
    `,
};
