import { Link } from 'react-router-dom';
import { Carousel, Image } from 'react-bootstrap';
import Message from './Message';
import { useGetFeaturedArtworksQuery } from '../slices/artworksApiSlice';

const ArtworksCarousel = () => {
  const { data: artworks, isLoading, error } = useGetFeaturedArtworksQuery();

  return isLoading ? (
    <></>
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Carousel pause="hover" className="mb-4">
      {artworks.map((artwork) => (
        <Carousel.Item key={artwork._id} className="text-center">
          <Link to={`artwork/${artwork._id}`}>
            <Image
              className="d-block mx-auto w-50"
              src={artwork.image}
              alt={artwork.name}
              fluid
            />
            <Carousel.Caption className="carousel-caption">
              <h4>{artwork.name}</h4>
            </Carousel.Caption>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ArtworksCarousel;
