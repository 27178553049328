import { Container, Row, Col } from 'react-bootstrap';
import IGsvg from '../../src/assets/Instagram_gray.svg';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <Container>
        <Row>
          <Col className="text-center py-3">
            <a
              href="https://www.instagram.com/limbism/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="ig logo" src={IGsvg}></img>
            </a>
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col className="py-3">
            <div style={{ textAlign: 'right' }}>
              <a
                href="mailto:limbism.art@gmail.com"
              >
                Contact Limbism
              </a>
            </div>
          </Col>
          <Col className="py-3">
            <p>Limbism &copy; {currentYear}</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
