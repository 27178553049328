import { createSlice } from '@reduxjs/toolkit';
import { updateCart } from '../utils/cartUtils';

const initialState = localStorage.getItem('cart')
  ? JSON.parse(localStorage.getItem('cart'))
  : {
      cartItems: [],
      shippingAddress: {},
      paymentMethod: 'PayPal',
    };

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, { payload }) => {
      const item = payload;
      const existingItem = state.cartItems.find((ci) => ci._id === item._id);
      if (existingItem) {
        state.cartItems = state.cartItems.map((ci) =>
          ci._id === existingItem._id ? item : ci
        );
      } else {
        state.cartItems = [...state.cartItems, item];
      }
   return updateCart(state);
    },
    removeFromCart: (state, { payload }) => {
      state.cartItems = state.cartItems.filter((ci) => ci._id !== payload);
      return updateCart(state);
    },
    saveShippingAddress: (state, { payload }) => {
      state.shippingAddress = payload;
      return updateCart(state);
    },
    savePaymentMethod: (state, { payload }) => {
      state.paymentMethod = payload;
      return updateCart(state);
    },
    clearCart: (state) => {
      state.cartItems = [];
      return updateCart(state);
    },
  },
});
export const {
  addToCart,
  removeFromCart,
  saveShippingAddress,
  savePaymentMethod,
  clearCart,
} = cartSlice.actions;

export default cartSlice.reducer;
