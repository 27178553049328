import { ARTWORKS_URL, UPLOADS_URL } from '../constants';
import { apiSlice } from './apiSlice';

export const artworksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getArtworks: builder.query({
      query: ({ keyword, pageNumber }) => ({
        url: ARTWORKS_URL,
        params: { keyword, pageNumber },
      }),
      providesTags: ['Artworks'],
      keepUnusedDataFor: 5,
    }),
    getArtworkDetails: builder.query({
      query: (artworkId) => ({ url: `${ARTWORKS_URL}/${artworkId}` }),
      keepUnusedDataFor: 5,
    }),
    createArtwork: builder.mutation({
      query: () => ({
        url: ARTWORKS_URL,
        method: 'POST',
      }),
      invalidatesTags: ['Artworks'],
    }),
    updateArtwork: builder.mutation({
      query: (data) => ({
        url: `${ARTWORKS_URL}/${data.artworkId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Artworks'],
    }),
    uploadArtworkImage: builder.mutation({
      query: (data) => ({
        url: UPLOADS_URL,
        method: 'POST',
        body: data,
      }),
    }),
    deleteArtwork: builder.mutation({
      query: (artworkId) => ({
        url: `${ARTWORKS_URL}/${artworkId}`,
        method: 'DELETE',
      }),
    }),
    createReview: builder.mutation({
      query: (data) => ({
        url: `${ARTWORKS_URL}/${data.artworkId}/reviews`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Product'],
    }),

    getFeaturedArtworks: builder.query({
      query: () => ({
        url: `${ARTWORKS_URL}/featured`,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useGetArtworksQuery,
  useGetArtworkDetailsQuery,
  useCreateArtworkMutation,
  useUpdateArtworkMutation,
  useUploadArtworkImageMutation,
  useDeleteArtworkMutation,
  useCreateReviewMutation,
  useGetFeaturedArtworksQuery,
} = artworksApiSlice;
