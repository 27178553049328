import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Artwork from '../components/Artwork';
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate';
import ArtworksCarousel from '../components/ArtworksCarousel';
import { useGetArtworksQuery } from '../slices/artworksApiSlice';

const HomeScreen = () => {
  const { pageNumber, keyword } = useParams();
  const { data, isLoading, error } = useGetArtworksQuery({
    keyword,
    pageNumber,
  });
  return (
    <>
      {!keyword ? (
        <ArtworksCarousel />
      ) : (
        <Link to="/" className="btn btn-light mb-4">
          Go Back
        </Link>
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <h1>Latest artworks</h1>
          <Row>
            {data.artworks.map((artwork) => (
              <Col key={`artwork-${artwork._id}`} sm={12} md={6} lg={4} xl={3}>
                <Artwork artwork={artwork} />
              </Col>
            ))}
          </Row>
          <Paginate
            pages={data.pages}
            page={data.page}
            keyword={keyword ? keyword : ''}
          />
        </>
      )}
    </>
  );
};

export default HomeScreen;
